import React from 'react'
import { Heading, Box, Text } from 'theme-ui'

/**
 * Shadow me to add your own content
 */

const styles = {
  wrapper: {
    ml: 3
  },
  heading: {
    color: `alpha`
  },
  running: {
    color: `omegaDark`,
    fontWeight: `body`
  }
}

export default () => (
    <Box sx={styles.wrapper}>
      <center><h2><a href="https://twitter.com/shakeshoutout">shake shoutout</a></h2>The top three domains won over the last 24 hours on the Handshake network.<br /><br />🏆 https://taas/<br />2,000.00 HNS<br />0.01 ETH<br />$17.60<br /><br />🥈 https://brea/<br />751.00 HNS<br />0.00 ETH<br />$6.61<br /><br />🥉 https://saar/<br />520.00 HNS<br />0.00 ETH<br />$4.57<br /><br /><br /><br /><br /><br /><h2><a href="https://twitter.com/nbshoutout">nb shoutout</a></h2>The top three domains bought over the last 24 hours in the Namebase marketplace.<br /><br />🏆 https://lands/<br />100,000.00 HNS<br />0.33 ETH<br />$880.74<br /><br />🥈 https://madeinusa/<br />35,000.00 HNS<br />0.12 ETH<br />$308.26<br /><br />🥉 https://teaser/<br />10,000.00 HNS<br />0.03 ETH<br />$88.07<br /><br /><br /><br /><br /><br /><h2><a href="https://twitter.com/shake_supply">shake supply</a></h2>The circulating HNS supply against the maximum supply.<br /><br />[ ▉▉▉▉▉▍▁▁▁▁▁▁▁▁▁▁▁ ] 31.85%<br /><br /><br /><br /><br /><br /><h2><a href="https://twitter.com/shakesplit">shake split</a></h2>The progress made toward the next Handshake halving.<br /><br />[ ▉▉▉▉▉▉▉▉▉▍▁▁▁▁▁▁▁ ] 55.5%<br /><br /><br /><br /><br /><br /><h2><a href="https://twitter.com/shakefoss">shake foss</a></h2>The claimed HNS against the total airdrop to FOSS developers. And the new and total claims since the last tweet.<br /><br />[ ▍▁▁▁▁▁▁▁▁▁▁▁▁▁▁▁▁ ] 2.91% (New: 0 Total: 6542)<br /><br /><br /><br /><br /><br /></center>
		</Box>
	)